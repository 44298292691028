import React from 'react'
import { Box, Flex } from 'src/components/Box'
import Button from 'src/components/Button'
import { Modal } from 'src/components/Modal'
import Loading from 'src/components/Loading'
import { Body, Title1 } from 'src/components/text'
import { CompetitionInstructionsEntryId } from 'src/contentful/entryIds'
import { CompetitionInstructionsModel } from 'src/contentful/models'
import renderRichTextDocument from 'src/contentful/renderRichTextDocument'
import useContentfulEntry from 'src/hooks/useContentfulEntry'
import strings from 'src/strings'

// Ensure these strings exist
const competitionInstructions = strings.dashboard.instructor
  .competitionInstructions || {
  closeLabel: 'Close',
  confirmLabel: 'I Understand',
  errorMessage:
    'Unable to load competition instructions. Please try again later.',
}

// Default title to use during loading/error states
const defaultTitle = 'Competition Instructions'

export interface CompetitionInstructionsModalProps {
  closeModal: () => void
}

const CompetitionInstructionsModal: React.FunctionComponent<
  CompetitionInstructionsModalProps
> = ({ closeModal }) => {
  const { loading, error, fields } = useContentfulEntry<
    CompetitionInstructionsModel
  >(CompetitionInstructionsEntryId.TeacherInstructions)

  return (
    <Modal open>
      <Flex
        maxWidth="650px" // Wider than the default 410px in ConfirmationModal
        width="90vw"
        m="8"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        data-cy="modal"
      >
        {loading && (
          <>
            <Title1 color="purple">{defaultTitle}</Title1>
            <Box my="4" width="100%" p="4">
              <Loading />
            </Box>
          </>
        )}

        {error && (
          <>
            <Title1 color="purple">{defaultTitle}</Title1>
            <Box my="4" width="100%" p="4">
              <Body color="error">{competitionInstructions.errorMessage}</Body>
            </Box>
          </>
        )}

        {fields && (
          <>
            <Title1 color="purple">{fields.title}</Title1>
            <Box my="4" width="100%" p="4">
              {renderRichTextDocument(fields.body)}
            </Box>
          </>
        )}

        <Flex mt="6" justifyContent="center">
          <Button
            data-cy="confirm"
            type="button"
            minWidth="200px"
            onClick={closeModal}
          >
            {competitionInstructions.confirmLabel}
          </Button>
        </Flex>
      </Flex>
    </Modal>
  )
}

export default CompetitionInstructionsModal
