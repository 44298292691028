import React, { useState } from 'react'
import { Title1, Body } from 'src/components/text'
import { Flex, Grid } from 'src/components/Box'
import {
  ProjectDetailsWithNotification,
  sortByNotification,
} from 'src/lib/project'
import ProjectCard from './ProjectCard'
import CompetitionInstructionsModal from './CompetitionInstructionsModal'

interface Props {
  title: string
  projects: ProjectDetailsWithNotification[]
  cardsAreLinked?: boolean
  showAvatars?: boolean
  isInstructor?: boolean
}

const ProjectsLayout: React.FunctionComponent<Props> = ({
  title,
  projects,
  cardsAreLinked,
  showAvatars,
  isInstructor = false,
}) => {
  const [
    showCompetitionInstructions,
    setShowCompetitionInstructions,
  ] = useState(false)
  const closeCompetitionInstructions = () =>
    setShowCompetitionInstructions(false)

  const openCompetitionInstructions = () => setShowCompetitionInstructions(true)

  return (
    <Flex flexDirection="column" mt="3">
      <Title1>{title}</Title1>
      <Grid mt="7" gridGap="6" gridTemplateColumns="repeat(auto-fit, 370px)">
        {projects.length === 0 && <Body>No projects found</Body>}
        {projects.sort(sortByNotification).map(p => (
          <ProjectCard
            key={p.id}
            project={p}
            isLinkable={cardsAreLinked}
            showAvatars={showAvatars}
            openCompetitionInstructions={openCompetitionInstructions}
            isInstructor={isInstructor}
          />
        ))}
      </Grid>
      {showCompetitionInstructions && (
        <CompetitionInstructionsModal
          closeModal={closeCompetitionInstructions}
        />
      )}
    </Flex>
  )
}

export default ProjectsLayout
