/* eslint-disable prettier/prettier */

import LocalizedStrings from 'react-localization'
import { ProjectPhase, ProjectPhaseStep } from './lib/project'
import { GlobalGoal, ProjectPhaseState } from './graphql-generated'

export const strings = new LocalizedStrings({
         en: {
           dashboard: {
             dashboardTitle: 'My Dashboard',
             myClassTitle: 'My Class',
             createProjectButton: 'Create a Project',
             selectClassLabel: 'Select a class',
             classCodeTitle: 'Class Code',
             instructor: {
               createAClassButton: '+ Create a class',
               instructorTitle: 'Instructors',
               manageInstructorsButton: 'Add/Remove',
               classProjectsTab: 'Class Projects',
               readyForReviewTab: 'Ready for Review',
               classProjectsPanelTitle: 'All class projects',
               readyForReviewPanelTitle: 'Review student submissions',
               classProjectsPlaceholder: {
                 title: 'Your class hasn’t created any projects yet',
                 body:
                   'You can create a project for a team in your class or sit back and relax while your students take their first steps towards creating a better world',
               },
               readyForReviewPlaceholder: {
                 title: 'You have no team submissions to review',
                 body:
                   'Looks like all the teams in your class are working on their projects. Once a team submits a phase for review, it will appear here.',
               },
               reviewModals: {
                 commentLabel: 'Comments',
                 cancelLabel: 'Cancel',
                 phaseTitle: 'Phase',
                 projectTitle: 'Project',
                 return: {
                   body:
                     'Confirm that you are returning these files to the team for additional work in the phase.',
                   confirmLabel: 'Return to Team',
                   title: 'Return to Team',
                 },
                 approve: {
                   body:
                     'Confirm that you have reviewed all files and want to mark the phase complete for the team.',
                   confirmLabel: 'Approve Phase',
                   title: 'Approve Phase',
                 },
               },
               competitionInstructions: {
                 closeLabel: 'Close',
                 confirmLabel: 'I Understand',
                 errorMessage: 'Unable to load competition instructions. Please try again later.',
               },
             },
             student: {
               myProjectTab: 'My Projects',
               classProjectsTab: 'Class Projects',
               myProjectsPanelTitle: 'All my projects',
               classProjectPanelTitle: 'All class projects',
               classProjectsPlaceholder: {
                 title: 'Your class hasn’t created any projects yet',
                 body:
                   'But don’t worry great minds are at work. Check back to see what sustainable goals the teams in your class will work toward.',
               },
               myProjectPlaceholder: {
                 exploreTitle: 'Explore the innovator’s mindset',
                 exploreBody:
                   'Embrace your inner innovator by using the design thinking process to create solutions for local and global problems. You will help build a better world.',
                 exploreLink: 'Learn More',
                 getStartedTitle: 'Ready to get started?',
                 getStartedBody:
                   'Take your first step towards creating solutions that will help build communities and protect the environment.',
               },
             },
           },
           projectOverview: {
             projectTitle: 'Project',
             teamTitle: 'Team',
             globalGoalTitle: 'Global Goal',
             globalGoalAlt: 'Global goal icon',
             phasesTitle: 'Design Thinking Phases',
             editProject: 'Edit project',
             phasesDescription:
               'Explore the three phases of design thinking process that you will be working through on  every project. Within each phase, you can learn about design thinking, complete activities, and then submit work for feedback and approval.',
             profilePictureAlt: 'Profile picture for {name}',
             profilePictureAltEmpty: 'Empty profile picture',
             phaseCardActLearn: 'Explore',
             phaseCardActPitch: 'Submit your pitch',
             backToProjectHome: 'Back to Project Home',
             disclosureButton: 'View Details',
           },
           projectPhases: {
             [ProjectPhase.Understand]: {
               name: 'Understand',
               cardDescription:
                 'Discover the complexity of the problem and how it affects people',
               phaseHeaderDescription:
                 'Discover the complexity of the problem and how it affects people.',
             },
             [ProjectPhase.Ideate]: {
               name: 'Ideate',
               cardDescription:
                 'Stimulate your ideas to spark an innovative solution',
               phaseHeaderDescription:
                 'Stimulate your ideas to spark an innovative solution.',
             },
             [ProjectPhase.Prototype]: {
               name: 'Prototype',
               cardDescription:
                 'Build your solution and bring it to life',
               phaseHeaderDescription:
                 'Build your solution and bring it to life.',
             },
             [ProjectPhase.Pitch]: {
               name: 'Pitch',
               cardDescription: 'Share your solution with others',
               phaseHeaderDescription:
                 'Share your solution with others.',
             },
           },
           projectPhaseSteps: {
             [ProjectPhaseStep.Explore]: {
               name: 'Explore',
             },
             [ProjectPhaseStep.Do]: {
               name: 'Do',
             },
             [ProjectPhaseStep.Document]: {
               name: 'Document',
             },
           },
           projectPhaseState: {
             [ProjectPhaseState.Approved]: 'Approved',
             [ProjectPhaseState.Returned]: 'Returned',
             [ProjectPhaseState.Submitted]: 'Submitted',
           },
           projectPitchApproved: '🎉 Approved 🎉',
           projectStatusHistory: {
             [ProjectPhaseState.Approved]: 'Phase Approved',
             [ProjectPhaseState.Returned]: 'Phase Returned',
             [ProjectPhaseState.Submitted]: 'Phase Submitted',
           },
           globalGoals: {
             [GlobalGoal.NoPoverty]: {
               number: '1',
               name: 'No Poverty',
             },
             [GlobalGoal.ZeroHunger]: {
               number: '2',
               name: 'Zero Hunger',
             },
             [GlobalGoal.GoodHealthAndWellBeing]: {
               number: '3',
               name: 'Good Health and Well-Being',
             },
             [GlobalGoal.QualityEducation]: {
               number: '4',
               name: 'Quality Education',
             },
             [GlobalGoal.GenderEquality]: {
               number: '5',
               name: 'Gender Equality',
             },
             [GlobalGoal.CleanWaterAndSanitation]: {
               number: '6',
               name: 'Clean Water and Sanitation',
             },
             [GlobalGoal.AffordableAndCleanEnergy]: {
               number: '7',
               name: 'Affordable and Clean Energy',
             },
             [GlobalGoal.DecentWorkAndEconomicGrowth]: {
               number: '8',
               name: 'Decent Work and Economic Growth',
             },
             [GlobalGoal.IndustryInnovationAndInfrastructure]: {
               number: '9',
               name: 'Industry, Innovation, and Infrastructure',
             },
             [GlobalGoal.ReducedInequalities]: {
               number: '10',
               name: 'Reduced Inequalities',
             },
             [GlobalGoal.SustainableCitiesAndCommunities]: {
               number: '11',
               name: 'Sustainable Cities and Communities',
             },
             [GlobalGoal.ResponsibleConsumptionAndProduction]: {
               number: '12',
               name: 'Responsible Production and Consumption',
             },
             [GlobalGoal.ClimateAction]: {
               number: '13',
               name: 'Climate Action',
             },
             [GlobalGoal.LifeBelowWater]: {
               number: '14',
               name: 'Life Below Water',
             },
             [GlobalGoal.LifeOnLand]: {
               number: '15',
               name: 'Life On Land',
             },
             [GlobalGoal.PeaceJusticeAndStrongInstitutions]: {
               number: '16',
               name: 'Peace, Justice, and Strong Institutions',
             },
             [GlobalGoal.PartnershipsForTheGoals]: {
               number: '17',
               name: 'Partnerships for the Goals',
             },
           },
           documentStep: {
             documentsColumnTitle: 'Linked Files',
             historyColumnTitle: 'Teacher Feedback',
             headerDescription:
               'Link your files for your teacher below. Once your team is ready, you can send the files to your teacher for review.',
             openFilePickerLabel: '+ Add',
             placeholderNoDocuments:
               'Your team hasn’t linked any files yet. Select the  + Add button above.',
             placeholderNoFeedback:
               'Submit files to get feedback from your teacher.',
             submitButtonLabel: 'Submit Files',
             submitColumnBody:
               'Submit the linked files to your teacher for review.',
             submitColumnTitle: 'Ready for Feedback?',
             fileSharingErrorBody: `We recommend requesting permission to edit the file on Google Drive, or updating share permissions so that anyone with a link can view. If you’re having trouble, {contactUsLink}.`,
             fileSharingContactLink: 'contact us',
           },
           submitPhaseModal: {
             body:
               'Would you like to send files for the {phaseName} phase to your teacher? There {isPluralize} {count} {documentPluralize} linked.',
             cancelButtonLabel: 'Cancel',
             documentSingular: 'document',
             isPlural: 'are',
             isSingular: 'is',
             submitButtonLabel: 'Send to Teacher',
             title: 'Submit {phaseName} Phase',
           },
           StudentSignupForm: {
             title: 'Create a student account',
             subtitle: 'Enter your 4-character class code to sign up',
             button: 'Sign up with Google',
             ageConfirmation: 'I am 13 years or older',
             terms: 'I agree to the {termsLink} and {privacyLink}',
             privacyLink: 'Privacy Policy',
             termsLink: 'Terms of Use',
             alreadyHaveAccount: 'Already have an account? {signinLink}',
             notFoundTitle: 'Account not found',
           },
           joinClassGroupModal: {
             title: 'Join another class',
             subtitle: 'Enter your 4-character class code',
             cancelButtonText: 'Cancel',
             joinButtonText: 'Join Class',
           },
           createClassGroupModal: {
             title: 'Create a class',
             cancelButtonText: 'Cancel',
             createButtonText: 'Create',
           },
           manageCoTeacherModal: {
             title: 'Manage Inscructors for this Class',
             subTitle: 'Add or remove instructors for this class.',
             instructorLabel: 'Instructor',
             instructorPlaceholder: 'Search for instructors',
             cancelButtonText: 'Cancel',
             createButtonText: 'Save',
           },
           TeacherSignupForm: {
             messageClassCreationNotAllowed:
               'Class creation is currently unavailable.',
             title: 'Create a teacher account',
             subtitle: 'Enter the requested information below.',
             classNameLabel: 'Class Name',
             classNamePlaceholder:
               'Your class name will appear throughout Pactful',
             classDescriptionLabel: 'Describe your class',
             classDescriptionPlaceholder:
               'Describe the class you are going to use with Pactful',
             organizationNameLabel: 'School/Organization',
             organizationNamePlaceholder:
               'Enter the name of your school/organization',
             cityLabel: 'City',
             cityPlaceholder: "Enter your school/organization's city",
             stateLabel: 'State/Province',
             statePlaceholder:
               "Enter your school/organization's state or province",
             postalCodeLabel: 'Postal Code',
             postalCodePlaceholder:
               "Enter your school/organization's postal or ZIP code",
             countryLabel: 'Country',
             countryPlaceholder:
               "Enter your school/organization's country",
             jobTitleLabel: 'Job Title',
             jobTitlePlaceholder: 'Enter your job title',
             studentGradeLevelsLabel: 'Student Grade Level(s)',
             studentGradeLevelLabel1: '7th Grade (13 years old)',
             studentGradeLevelLabel2: '8th Grade (13–14 years old)',
             studentGradeLevelLabel3: '9th Grade (14–15 years old)',
             studentGradeLevelLabel4: '10th Grade (15–16 years old)',
             studentGradeLevelLabel5: '11th Grade (16–17 years old)',
             studentGradeLevelLabel6: '12th Grade (17–18 years old)',
             studentGradeLevelLabel7: 'Other/Adults',
             studentTypeDecriptionPlaceholder:
               'What are the ages and grade levels of your students?',
             button: 'Sign up with Google',
             ageConfirmation: 'I am 18 years or older',
             terms: 'I agree to the {termsLink} and {privacyLink}',
             privacyLink: 'Privacy Policy',
             termsLink: 'Terms of Use',
             alreadyHaveAccount: 'Already have an account? {signinLink}',
             notFoundTitle: 'Account not found',
           },
           homepage: {
             title: 'Welcome to Pactful',
             body1:
               '{pactfulLink} is the social innovation tool for students and teachers who want to build a better world.',
             body2:
               'Pactful requires the use of a Google Account. Students who submit files to teachers for review will be asked to allow Pactful to manage those files to change the share settings, so teachers may review and comment on submissions.',
             signUpLabel: 'Create Account',
             privacyLink: 'Privacy Policy',
             termsLink: 'Terms of Use',
             pactfulLink: 'Pactful',
             errorUserNotFound:
               'You do not have a Pactful account. Please click Create Account and provide the needed information.',
           },
           chooseAccountType: {
             title: 'Choose an Account Type',
             teacherLabel: 'Teacher',
             studentLabel: 'student',
           },
           projectForm: {
             createAction: 'Create Project',
             createFormTitle: 'Create Project',
             editFormTitle: 'Edit Project',
             editAction: 'Update Project',
             deleteProjectField: {
               deleteLinkText: 'DELETE PROJECT',
               confirmationModalTitle: 'Confirm Project Deletion',
               confirmationModalCancelButtonText: 'Cancel',
               confirmationModalDeleteButtonText: 'Delete Project',
               deleteConfirmationInstruction:
                 'This action will permanently delete this project.',
             },
             globalGoalField: {
               explanation:
                 'Start by choosing a {globalGoalLink} for your project. Select a goal that is related to the local or global problem you’d like to work on.',
               learnMore:
                 'Unsure about which goal to choose? Don’t worry, you can learn more about all the {globalGoalsLink} through our {innovatorsGuideLink}.',
               globalGoalLink: 'Global Goal',
               globalGoalsLink: 'Global Goals',
               innovatorsGuideLink: "Innovator's Guide",
               fieldTitle: 'Select a Global Goal',
             },
             teamField: {
               globalGoalTitle: 'Global Goal',
               changeGoal: 'change goal',
               formTeamTitle: 'Your Team',
               teamNameLabel: 'Team Name',
               teamNamePlaceholder: 'Give your team a unique name',
               memberSearchLabel: 'Team Members (optional)',
               memberSearchPlaceholder: 'Add team members',
             },
             projectDetails: {
               title: 'Your Project',
               nameLabel: 'Project Name',
               namePlaceholder: 'Give your project a unique name',
               descriptionLabel: 'Description',
               descriptionPlaceholder:
                 'Describe your project in more detail.',
             },
           },
           signIn: 'Sign in',
           groupClassCodeField: {
             label: 'Class Code',
             placeholder: 'Enter your class code',
             invalidCodeError: "We can't verify your code. Try again.",
           },
           formValidation: {
             required: 'Required',
             genericError: 'Something went wrong',
             optional: '(optional)',
           },
           searchInput: {
             noResults: 'No Results',
           },
           formWizard: {
             next: 'Next',
             cancel: 'Cancel',
           },
           projectCard: {
             approveLabel: 'Approve Phase',
             goalTitle: 'Goal {goalNumber}',
             teamTitle: 'Team',
             pitchVideoTitle: 'Pitch Video',
             progressTitle: 'Progress',
             returnLabel: 'Return to Team',
             submittedDocumentsTitle: 'Submitted Documents',
             approvePhaseButton: '\u2713 Approve Phase',
             competitionInstructionsButton: 'Challenge Entry',
           },
           pageTitles: {
             template: '%s - Pactful',
             defaultTitle: 'Pactful',
             phaseSuffix: 'Pactful',
             editProject: 'Edit {projectName}',
             joinClassGroup: 'Join Class',
             dashboardTitle: 'Dashboard',
             createProject: 'Create Project',
             showcaseTitle: 'Showcase',
             innovatorGuideTemplate: "%s - Innovator's Guide - Pactful",
             innovatorGuideDefault: "Innovator's Guide - Pactful",
             notFound: 'Not Found',
             somethingWentWrong: 'Something went wrong',
             forbidden: 'Not Authorized',
           },
           innovatorsGuide: {
             heading: "Innovator's Guide",
             subheading:
               'This is your guide to all things innovation and social impact.',
             tabs: {
               gettingStarted: 'Getting Started',
               globalGoals: 'Global Goals',
               resources: 'Resources',
             },
           },
           showcase: {
             heading: 'Showcase',
             filter: { placeholderText: 'Filter by Global Goal...' },
             showComplete: 'Show only completed projects',
             showcaseProjectsPlaceholder: {
               title: 'No projects found',
               body: 'There are no projects matching your criteria.',
             },
           },
           header: {
             myDashboard: 'My Dashboard',
             community: 'Community',
             showcase: 'Showcase',
             innovatorsGuide: "Innovator's Guide",
             logout: 'Sign out',
             contactUs: 'Need help or have questions? {contactUsLink}',
             contactUsLink: 'Contact us',
             joinClass: 'Join a Class',
             toggleEnglish: 'Español',
             toggleSpanish: 'English',
           },
           footer: {
             innovatorGuideTitle: "Innovator's Guide",
             gettingStartedLink: 'Getting Started',
             globalGoalLink: 'Global Goals',
             resourceLink: 'Resources',
             teacherGuideLink: "Teacher's Guide",
             dashboardTitle: 'My Dashboard',
             myProjectLink: 'My Projects',
             classProjectsLink: 'Class Projects',
             contactTitle: 'Contact Us',
             termsLink: 'Terms of Use',
             privacyLink: 'Privacy Policy',
           },
           links: {
             contactPath: 'https://forms.gle/ALUoyELoxq1Rz4Sr8',
             pactfulMarketingSitePath: 'https://www.pactful.org',
             teacherGuidePath:
               'https://docs.google.com/document/d/1R3EF38pFIqFFtnjFI_59HVMLJxFY6TX65xuTDCdqjO4/edit?usp=sharing',
           },
           notFound: {
             title: "We can't find this page",
             loggedInLink: 'Back to Dashboard',
             defaultLink: 'Back',
             body: `If you reached this page from a link on {siteLink}, please {contactUsLink}
      about the problem.`,
             siteLink: 'pactful.org',
             contactUsLink: 'contact us',
           },
           somethingWentWrong: {
             title: "Something went wrong, and we're trying to fix it.",
             body: `There's been an error on our server. Return to {siteLink}, or {contactUsLink} so we can work on this problem.`,
             siteLink: 'pactful.org',
             contactUsLink: 'contact us',
           },
           unauthorized: {
             title: 'Unauthorized',
             body: "You're not authorized to view this content.",
           },
           errors: {
             invalidFilePermission: 'Unable to share {documentName}',
             forbidden: 'Unauthorized',
             generic: 'Oops! Something isnt right here',
             fileSharing:
               'Something went wrong while linking these files:',
           },
           emailTemplates: {
             classCreated: {
               subject: 'Your Pactful class has been created',
               body: `
        <!doctype html>
        <html>
        <head>
        </head>
        <body>
        <p>Welcome to Pactful! We are so thrilled that you're joining us on our journey to build a better world. Your class has been created and you’re ready to get started.</p>
        <p>You can invite students to your class using your class code (always shown on your dashboard) using the process below:</p>
        <ol>
            <li>Go to <a href="https://www.pactful.org">pactful.org</a> and click the <strong>Sign In > Have a class code? Create an Account</strong>.</li>
            <li>Enter your class code of <strong>{{CLASS_CODE}}</strong> and click <strong>Sign up With Google</strong>.</li>
        </ol>
        <p>In addition, we welcome your involvement with the <a href="https://community.pactful.org/">Pactful Community</a> where you can engage with other educators, the team from the Jacobs Institute, and get support using the support category.</p>
        <p>
            <strong>Pactful Support</strong><br>
            <a href="mailto:support@pactful.org">support@pactful.org</a>
        </p>
        <p><em>Pactful® is a social good innovation curriculum and tool for teens and teachers who want to build a better world. Pactful is created and supported by the <a href="https://www.sandiego.edu/soles/jacobs-institute/">Jacobs Institute for Innovation in Education</a> at the University of San Diego.</em></p>
        </body>
        </html>
        `,
             },
           },
         },
         es: {
           dashboard: {
             dashboardTitle: 'Mi Tablero',
             myClassTitle: 'Mi Clase',
             createProjectButton: 'Crear un Proyecto',
             selectClassLabel: 'Seleccione una clase',
             classCodeTitle: 'Código de clase',
             instructor: {
               createAClassButton: '+ Crear una Clase',
               instructorTitle: 'Instructores',
               manageInstructorsButton: 'Agregar/Eliminar',
               classProjectsTab: 'Proyectos de Clase',
               readyForReviewTab: 'Listo Para Revisar',
               classProjectsPanelTitle: 'Todos los proyectos de clase',
               readyForReviewPanelTitle:
                 'Revisar entregas de estudiantes',
               classProjectsPlaceholder: {
                 title: 'Su clase no ha creado ningún proyecto',
                 body:
                   'Crea un proyecto para un equipo de su clase o relájese mientras sus alumnos dan sus primeros pasos para cree un mundo mejor',
               },
               readyForReviewPlaceholder: {
                 title: 'No tiene ningúna entrega para revisar',
                 body:
                   'Parece que todos los equipos de su clase están trabajando en sus proyectos. Cuando un equipo envíe una fase para su revisión, aparecerá aquí .',
               },
               reviewModals: {
                 commentLabel: 'Comentarios',
                 cancelLabel: 'Cancelar',
                 phaseTitle: 'Fase',
                 projectTitle: 'Proyecto',
                 return: {
                   body:
                     'Confirme que va a devolver los archivos al equipo para trabajar más en la fase.',
                   confirmLabel: 'Regresar al equipo',
                   title: 'Regresar al equipo',
                 },
                 approve: {
                   body:
                     'Confirme que ha revisado todos los archivos y desea marcar la fase completada para el equipo.',
                   confirmLabel: 'Aprobar fase',
                   title: 'Aprobar fase',
                 },
               },
               competitionInstructions: {
                 closeLabel: 'Cerrar',
                 confirmLabel: 'Entiendo',
                 errorMessage: 'No se pudieron cargar las instrucciones de la competencia. Por favor, inténtelo de nuevo más tarde.',
               },
             },
             student: {
               myProjectTab: 'Mis proyectos',
               classProjectsTab: 'Proyectos de clase',
               myProjectsPanelTitle: 'Todos mis proyectos',
               classProjectPanelTitle: 'Todos los proyectos de clase.',
               classProjectsPlaceholder: {
                 title: 'Tu clase aún no ha creado ningún proyecto.',
                 body:
                   'Pero no te preocupes, hay grandes mentes trabajando. Vuelva de nuevo para ver en qué objetivos sostenibles trabajarán los equipos de su clase.',
               },
               myProjectPlaceholder: {
                 exploreTitle: 'Explora la mentalidad del innovador.',
                 exploreBody:
                   'Abraza al innovador que lleva dentro utilizando el proceso de pensamiento de diseño para crear soluciones para problemas locales y globales. Ayudarás a construir un mundo mejor.',
                 exploreLink: 'Aprende más.',
                 getStartedTitle: '¿Listo para empezar?',
                 getStartedBody:
                   'Toma tu primer paso hacia la creación de soluciones que ayudarán a construir comunidades y proteger el medio ambiente.',
               },
             },
           },
           projectOverview: {
             projectTitle: 'Proyecto',
             teamTitle: 'Equipo',
             globalGoalTitle: 'Meta Global',
             globalGoalAlt: 'Icono de objetivo global',
             phasesTitle: 'Fases del pensamiento de diseño',
             editProject: 'Editar proyecto',
             phasesDescription:
               'Explora las tres fases del proceso de pensamiento de diseño que vas a usar para cada proyecto. Dentro de cada fase, puedes aprender sobre el pensamiento de diseño, completar actividades y entregar trabajo para recibir comentarios y aprobación.',
             profilePictureAlt: 'Foto del perfil de {nombre}',
             profilePictureAltEmpty: 'Foto del perfil vacío',
             phaseCardActLearn: 'Explorar',
             phaseCardActPitch: 'Entrega tu propuesta',
             backToDashboard: 'Volver al tablero',
             backToProjectHome:
               'Volver a la página principal del proyecto',
             disclosureButton: 'Ver detalles',
           },
           projectPhases: {
             [ProjectPhase.Understand]: {
               name: 'Entender',
               cardDescription:
                 'Descubre la complejidad del problema y cómo afecta a todos.',
               phaseHeaderDescription:
                 'Descubre la complejidad del problema y cómo afecta a todos.',
             },
             [ProjectPhase.Ideate]: {
               name: 'Idear',
               cardDescription:
                 'Estimula tus ideas para generar una solución innovadora.',
               phaseHeaderDescription:
                 'Estimula tus ideas para generar una solución innovadora.',
             },
             [ProjectPhase.Prototype]: {
               name: 'Prototipo',
               cardDescription: 'Construye tu solución y dale vida',
               phaseHeaderDescription:
                 'Construye tu solución y dale vida.',
             },
             [ProjectPhase.Pitch]: {
               name: 'Presentar',
               cardDescription: 'Comparte tu solución con otros',
               phaseHeaderDescription: 'Comparte tu solución con otros.',
             },
           },
           projectPhaseSteps: {
             [ProjectPhaseStep.Explore]: {
               name: 'Explorar',
             },
             [ProjectPhaseStep.Do]: {
               name: 'Hacer',
             },
             [ProjectPhaseStep.Document]: {
               name: 'Documentar',
             },
           },
           projectPhaseState: {
             [ProjectPhaseState.Approved]: 'Aprobado',
             [ProjectPhaseState.Returned]: 'Regresado',
             [ProjectPhaseState.Submitted]: 'Entregado',
           },
           projectPitchApproved: '🎉 Aprobado  🎉',
           projectStatusHistory: {
             [ProjectPhaseState.Approved]: 'Fase Aprobada',
             [ProjectPhaseState.Returned]: 'Fase Regresada',
             [ProjectPhaseState.Submitted]: 'Fase Entregada',
           },
           globalGoals: {
             [GlobalGoal.NoPoverty]: {
               number: '1',
               name: 'No Pobreza',
             },
             [GlobalGoal.ZeroHunger]: {
               number: '2',
               name: 'Cero Hambre',
             },
             [GlobalGoal.GoodHealthAndWellBeing]: {
               number: '3',
               name: 'Buen Salud y Bienestar',
             },
             [GlobalGoal.QualityEducation]: {
               number: '4',
               name: 'Educación de Calidad',
             },
             [GlobalGoal.GenderEquality]: {
               number: '5',
               name: 'Igualdad de Género',
             },
             [GlobalGoal.CleanWaterAndSanitation]: {
               number: '6',
               name: 'Agua Potable y Sanitización',
             },
             [GlobalGoal.AffordableAndCleanEnergy]: {
               number: '7',
               name: 'Energía Limpia y Accesible',
             },
             [GlobalGoal.DecentWorkAndEconomicGrowth]: {
               number: '8',
               name: 'Trabajo Decente y Crecimiento Económico',
             },
             [GlobalGoal.IndustryInnovationAndInfrastructure]: {
               number: '9',
               name: 'Industria, Innovación, e Infraestructura',
             },
             [GlobalGoal.ReducedInequalities]: {
               number: '10',
               name: 'Desigualdades Reducidas',
             },
             [GlobalGoal.SustainableCitiesAndCommunities]: {
               number: '11',
               name: 'Ciudades y Comunidades Sustentables',
             },
             [GlobalGoal.ResponsibleConsumptionAndProduction]: {
               number: '12',
               name: 'Producción y Consumo Responsable',
             },
             [GlobalGoal.ClimateAction]: {
               number: '13',
               name: 'Accion Climatica',
             },
             [GlobalGoal.LifeBelowWater]: {
               number: '14',
               name: 'Vida Marina',
             },
             [GlobalGoal.LifeOnLand]: {
               number: '15',
               name: 'Vida en Tierra',
             },
             [GlobalGoal.PeaceJusticeAndStrongInstitutions]: {
               number: '16',
               name: 'Paz, Justicia, y Instituciones Fuertes',
             },
             [GlobalGoal.PartnershipsForTheGoals]: {
               number: '17',
               name: 'Alianzas para las Metas',
             },
           },
           documentStep: {
             documentsColumnTitle: 'Archivos Incluidos',
             historyColumnTitle: 'Comentarios del profesor',
             headerDescription:
               'Agrega tus archivos para tu instructor abajo. Cuando tu equipo esté listo, puedes enviar los archivos a tu instructor para que los revise.',
             openFilePickerLabel: '+ Agregar',
             placeholderNoDocuments:
               'Tu equipo aún no ha agregado ningún archivo. Seleccione el botón + Agregar arriba.',
             placeholderNoFeedback:
               'Envía archivos para recibir comentarios de tu instructor.',
             submitButtonLabel: 'Entrega Archivos',
             submitColumnBody:
               'Entrega los archivos para la revisión de tu instructor.',
             submitColumnTitle: 'Listo para comentarios del instructor?',
             fileSharingErrorBody: `Recomendamos solicitar permiso para editar el archivo en Google Drive o actualizar los permisos para compartir para que cualquier persona pueda ver el enlace. Si tienes problemas, {contactUs Link} .`,
             fileSharingContactLink: 'Contáctanos',
           },
           submitPhaseModal: {
             body:
               '¿Te gustaría enviar archivos para la fase {phaseName} a tu instructor? Hay {isPluralize} {count} {documentPluralize} agragados..',
             cancelButtonLabel: 'Cancelar',
             documentSingular: 'documento',
             isPlural: 'son',
             isSingular: 'es',
             submitButtonLabel: 'Manda a tu instructor',
             title: 'Entregar {faseNombre} Fase',
           },
           StudentSignupForm: {
             title: 'Crear una cuenta del estudiante',
             subtitle:
               'Ingresa tu código de clase de 4 caracteres para registrarte.',
             button: 'Ingresa con Google',
             ageConfirmation: 'Tengo 13 años o más',
             terms: 'Acepto {termsLink} y {privacyLink}',
             privacyLink: 'Política de Privacidad',
             termsLink: 'Terminos de Uso',
             alreadyHaveAccount: 'Ya tienes una cuenta?  {signinLink}',
             notFoundTitle: 'No se encuentra la cuenta',
           },
           joinClassGroupModal: {
             title: 'Agrega otra clase',
             subtitle: 'Ingresa tu código de clase de 4 caracteres',
             cancelButtonText: 'Cancelar',
             joinButtonText: 'Únite a la clase',
           },
           createClassGroupModal: {
             title: 'Crear una clase',
             cancelButtonText: 'Cancelar',
             createButtonText: 'Crear',
           },
           manageCoTeacherModal: {
             title: 'Manejar Instructores para esta clase',
             subTitle: 'Agregar o quitar instructores para esta clase',
             instructorLabel: 'Instructor',
             instructorPlaceholder: 'Buscar Instructores',
             cancelButtonText: 'Cancelar',
             createButtonText: 'Guardar',
           },
           TeacherSignupForm: {
             messageClassCreationNotAllowed:
               'La creación de clases no está disponible actualmente .',
             title: 'Crear una cuenta de instructor',
             subtitle: 'Ingrese la información solicitada abajo.',
             classNameLabel: 'Nombre de Clase',
             classNamePlaceholder:
               'Su nombre de clase aparecerá por la aplicación de Pactful',
             classDescriptionLabel: 'Describa su clase',
             classDescriptionPlaceholder:
               'Describe la clase que utilizará con Pactful',
             organizationNameLabel: 'Escuela/Organización',
             organizationNamePlaceholder:
               'Ingrese el nombre de su escuela/organización',
             cityLabel: 'Ciudad',
             cityPlaceholder:
               'Ingrese la ciudad de su escuela/organización ',
             stateLabel: 'Estado/Provincia',
             statePlaceholder:
               'Ingrese el estado o provincia de su escuela/organización ',
             postalCodeLabel: 'Código Postal',
             postalCodePlaceholder:
               'Ingrese el código postal de su escuela/ organización ',
             countryLabel: 'País',
             countryPlaceholder:
               'Ingrese el país de su escuela/ organización ',
             jobTitleLabel: 'Título de Trabajo',
             jobTitlePlaceholder: 'Ingrese su título de trabajo',
             studentGradeLevelsLabel: 'Grado(s) de los estudiantes',
             studentGradeLevelLabel1: '7.º grado (13 años)',
             studentGradeLevelLabel2: '8.º grado (13 a 14 años)',
             studentGradeLevelLabel3: '9.º grado (14 a 15 años)',
             studentGradeLevelLabel4: '10.º grado (15 a 16 años)',
             studentGradeLevelLabel5: '11.º grado (16 a 17 años)',
             studentGradeLevelLabel6: '12.° grado (17 a 18 años)',
             studentGradeLevelLabel7: 'Otros/Adultos',
             studentTypeDecriptionPlaceholder:
               '¿Cuál es la edad y grado de sus estudiantes?',
             button: 'Ingrese con Google',
             ageConfirmation: 'Tengo 18 años o más',
             terms:
               'Estoy de acuerdo con los {termsLink} y {privacyLink}',
             privacyLink: 'Política de Privacidad',
             termsLink: 'Terminos de Uso',
             alreadyHaveAccount: 'Ya tienes una cuenta?  {signinLink}',
             notFoundTitle: 'No se encuentra la cuenta',
           },
           homepage: {
             title: 'Bienvenidos a Pactful',
             body1:
               '{pactfulLink} es la herramienta de innovación social para estudiantes e instructores que quieren construir un mundo mejor.',
             body2:
               'Pactful requiere el uso de una cuenta de Google. Pedirá a los estudiantes que envíen archivos a los instructores para su revisión que permitan que Pactful administre esos archivos para cambiar los permisos de compartir, para que los instructores puedan revisar y comentar en los envíos.',
             signUpLabel: 'Crear una Cuenta',
             privacyLink: 'Política de Privacidad',
             termsLink: 'Terminos de Uso',
             pactfulLink: 'Pactful',
             errorUserNotFound:
               'No tiene una cuenta con Pacful. Haga clic en Crear Cuenta y proporcione la información necesaria.',
           },
           chooseAccountType: {
             title: 'Elija un tipo de cuenta',
             teacherLabel: 'Instructor',
             studentLabel: 'Estudiante',
           },
           projectForm: {
             createAction: 'Crear proyecto',
             createFormTitle: 'Crear proyecto',
             editFormTitle: 'Editar proyecto',
             editAction: 'Actualizar Proyecto',
             deleteProjectField: {
               deleteLinkText: 'ELIMINAR PROYECTO',
               confirmationModalTitle:
                 'Confirmar eliminación de proyecto',
               confirmationModalCancelButtonText: 'Cancelar',
               confirmationModalDeleteButtonText: 'Eliminar Proyecto',
               deleteConfirmationInstruction:
                 'Ésta acción eliminará este proyecto permanentemente',
             },
             globalGoalField: {
               explanation:
                 'Comienza eligiendo un {globalGoalLink} para tu proyecto. Selecciona un objetivo que esté relacionado con el problema local o global en lo que te gustaría trabajar.',
               learnMore:
                 '¿No estás seguro de cuál objetivo elegir? No te preocupes, puedes conocer más sobre todos las {globalGoalsLink} a través de nuestra {innovatorsGuideLink} .',
               globalGoalLink: 'Meta Global',
               globalGoalsLink: 'Metas Globales',
               innovatorsGuideLink: 'Guía del Innovador ',
               fieldTitle: 'Seleccione un objetivo global',
             },
             teamField: {
               globalGoalTitle: 'Meta Global',
               changeGoal: 'cambiar meta',
               formTeamTitle: 'Tu Equipo',
               teamNameLabel: 'Nombre de Equipo',
               teamNamePlaceholder: 'Dale a tu equipo un nombre único.',
               memberSearchLabel: 'Miembros del equipo (opcional)',
               memberSearchPlaceholder: 'Agregar miembros al equipo.',
             },
             projectDetails: {
               title: 'Tu Proyecto',
               nameLabel: 'Nombre de Proyecto',
               namePlaceholder: 'Dale a tu equipo un nombre único.',
               descriptionLabel: 'Descripción',
               descriptionPlaceholder:
                 'Describe tu proyecto con más detalle.',
             },
           },
           signIn: 'Iniciar sesión',
           groupClassCodeField: {
             label: 'Código de clase',
             placeholder: 'Ingresa tu código de clase',
             invalidCodeError:
               'No podemos verificar tu código. Intenta otra vez. .',
           },
           formValidation: {
             required: 'Requerido',
             genericError: 'Algo salió mal',
             optional: '(opcional)',
           },
           searchInput: {
             noResults: 'No hay resultados',
           },
           formWizard: {
             next: 'Siguiente',
             cancel: 'Cancelar',
           },
           projectCard: {
             approveLabel: 'Aprobar Fase',
             goalTitle: 'Meta  {goalNumber}',
             teamTitle: 'Equipo',
             pitchVideoTitle: 'Vídeo de Presentación',
             progressTitle: 'Progreso',
             returnLabel: 'Regresar al equipo.',
             submittedDocumentsTitle: 'Documentos Entregados',
             approvePhaseButton: '\u2713 Aprobar Fase',
             competitionInstructionsButton: 'Instrucciones de Competencia',
           },
           pageTitles: {
             template: '%s - Pactful',
             defaultTitle: 'Pactful',
             phaseSuffix: 'Pactful',
             editProject: 'Editar  {projectName}',
             joinClassGroup: 'Ingresar a una clase',
             dashboardTitle: 'Tablero',
             createProject: 'Crear Proyecto',
             showcaseTitle: 'Escaparate',
             innovatorGuideTemplate:
               '%s - Guia de Innovadores - Pactful ',
             innovatorGuideDefault: 'Guia de Innovadores - Pactful ',
             notFound: 'No se Encuentra',
             somethingWentWrong: 'Algo salió mal',
             forbidden: 'No Autorizado',
           },
           innovatorsGuide: {
             heading: 'Guía del Innovador ',
             subheading:
               'Éste es tu guía sobre todo relacionado con la innovación y el impacto social.',
             tabs: {
               gettingStarted: 'Empezando',
               globalGoals: 'Metas Globales',
               resources: 'Recursos',
             },
           },
           showcase: {
             heading: 'Escaparate',
             filter: { placeholderText: 'Filtrar por Meta Global ...' },
             showComplete: 'Mostrar solamente los proyectos completados',
             showcaseProjectsPlaceholder: {
               title: 'No proyectos encontrados',
               body: 'No hay proyectos que coincidan con sus criterios.',
             },
           },
           header: {
             myDashboard: 'Mi tablero',
             community: 'Comunidad',
             showcase: 'Escaparate',
             innovatorsGuide: 'Guía del Innovador ',
             logout: 'Crear session',
             contactUs:
               'Necesitas ayuda o tienes preguntas?  {contactUsLink}',
             contactUsLink: 'Contáctanos',
             joinClass: 'Ingresar a una clase',
           },
           footer: {
             innovatorGuideTitle: 'Guía del Innovador ',
             gettingStartedLink: 'Empezando',
             globalGoalLink: 'Metas Globales',
             resourceLink: 'Recursos',
             teacherGuideLink: 'Guía del Instructor',
             dashboardTitle: 'Mi Tablero',
             myProjectLink: 'Mis Proyectos',
             classProjectsLink: 'Proyectos de Clase',
             contactTitle: 'Contáctanos',
             termsLink: 'Terminos de Uso',
             privacyLink: 'Política de Privacidad',
           },
           links: {
             contactPath:
               'https://docs.google.com/forms/d/e/1FAIpQLSfe75gveyvVJoi_omW744GpGv6FfeVV830N9ezRupV1G5cH3w/viewform?usp=sharing',
             pactfulMarketingSitePath: 'https://www.pactful.org',
             teacherGuidePath:
               'https://docs.google.com/document/d/1EiHH2XV62Kg4m0XLFWFNABvfsHxSd5BzWNS0Bq_z6K4/edit?usp=sharing',
           },
           notFound: {
             title: 'No pudimos encontrar esta página ',
             loggedInLink: 'Regresar al tablero',
             defaultLink: 'Regresar',
             body: `
Si llegó a esta página desde un enlace en {siteLink}, {contactUsLink} sobre el problema.`,
             siteLink: 'pactful.org',
             contactUsLink: 'contáctanos',
           },
           somethingWentWrong: {
             title:
               'Algo salió mal y estamos intentando solucionarlo. .',
             body: `Hubo un error en el servidor. Regrese a {siteLink} o a {contactUsLink} para que podamos corregir este problema. .`,
             siteLink: 'pactful.org',
             contactUsLink: 'contáctanos',
           },
           unauthorized: {
             title: 'No Autorizado',
             body: 'No estás autorizado a ver este contenido. ',
           },
           errors: {
             invalidFilePermission:
               'No se puede compartir {documentName}',
             forbidden: 'No autorizado',
             generic: '¡Ups! Algo no está bien aquí',
             fileSharing: 'Algo salió mal agregando los archivos:',
           },
           emailTemplates: {
             classCreated: {
               subject: 'Tu clase de Pactful ha sido creada.',
               body: `
         <!doctype html>
         <html>
         <head>
         </head>
         <body>
         <p>¡Bienvenidos a Pacful! Estamos muy emocionados de que usted se haya unido a nuestro viaje para construir un mundo mejor. Tu clase ha sido creada y estás listo para comenzar. </p>
         <p>Puede invitar estudiantes a su clase usando su código de clase (siempre se muestra en su tablero) usando el proceso a continuación. :</p>
         <ol>
            <li>Ir a  <a href="https://www.pactful.org">pactful.org</a> y haz clic en  <strong>Iniciar sesión  > ¿Tienes un código de clase? Crea una cuenta. </strong>.</li>
            <li>Ingrese su código de clase de  <strong>{{CLASS_CODE}}</strong> y haga clic  <strong>Ingresar con Google </strong>.</li>
         </ol>
         <p>Agradecemos su participación en <a href="https://community.pactful.org/">Pactful Community</a> donde puede interactuar con otros educadores y el equipo del Instituto Jacobs, y obtener apoyo utilizando la categoría de soporte. .</p>
         <p>
            <strong>Soporte </strong><br>
            <a href="mailto:support@pactful.org">support@pactful.org</a>
         </p>
         <p><em>Pactful® es un currículo y una herramienta de innovación social para adolescentes e instructores que desean construir un mundo mejor. Pactful es creado y respaldado por <a href="https://www.sandiego.edu/soles/jacobs-institute/">Jacobs Institute for Innovation in Education</a> at the University of San Diego.</em></p>
         </body>
         </html>
         `,
             },
           },
         },
       })

export default strings
